import { types } from "@proj/static";
import http from "../utils/http";

export const getTaxes = async() => {
    let result = await (await http().get(`misc/tax/all`));
    return result.data;
}

export const createTax = async(data:types.userTypes.IUser) => {
    let result = await (await http().post(`misc/tax/create`,data)).data;
    return result.data;
}

export const updateTax = async(id:string,data:types.userTypes.IUser) => {
    let result = await (await http().patch(`misc/tax/${id}`,data)).data;
    return result.data;
}

export const deleteTax = async(id:string) => {
    let result = await (await http().delete(`misc/tax/${id}`)).data;
    return result.data;
}