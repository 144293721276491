import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";

interface RequestProps {
  apiUrl?:string
  shouldUseToken?:boolean,
  token?:string
}


function http({apiUrl,shouldUseToken=true,token}:RequestProps = {}){
  const a = axios.create({
    baseURL:apiUrl || process.env.REACT_APP_API_URL,
  });
  
  a.interceptors.request.use(
    async (config:InternalAxiosRequestConfig) => {
      if(config.headers){
          config.headers["Content-Type"] = "application/json";
          if (shouldUseToken) {
            if(token && token !== ""){
              config.headers.Authorization = `Bearer ${token}`;
            }
            else{
              const auth = getAuth();
              await new Promise((resolve,reject)=>{
                onAuthStateChanged(auth,async user=>{
                  const token = await user?.getIdToken();
                  resolve(token);
                })
              }).then(token=>{
                config.headers.Authorization = `Bearer ${token}`;
              }).catch(err=>{
                console.error("Error retreiving token");
              })
            }
            
          }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  a.interceptors.response.use(
    (response) => {
      return response;
    },
    (error:Error | AxiosError) => {
      if(axios.isAxiosError(error)){
        if (error.response) {
          if (error.response.status === 401) {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error)
      }
      return Promise.reject(error);
    }
  );
  return a;
}




export default http;    