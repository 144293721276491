import { takeEvery,put } from "redux-saga/effects";
import * as Client from '../slices/client.slice';
import {types} from '@proj/static';
import * as clientServices from '../../services/client.services';

function* getClients(){
    try{
        let result:types.clientTypes.IClientWithCount[] = yield(clientServices.getClients());
        yield put(Client.getClientsSuccess(result));
    }
    catch(err){
        yield put(Client.getClientsFailed({
            error:err,
            message:"Error Occured"
        }))
    }
}


function* clientSaga(){
    yield takeEvery(Client.getClients.type,getClients)
}

export default clientSaga;