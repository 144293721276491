import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {types}  from '@proj/static';
import { Modal } from "antd";
import * as Order from '../slices/order.slice';

const commonSlice = createSlice({
    name:"common",
    initialState:{},
    reducers:{
        displayError:(state,action:PayloadAction<{key:string,error:types.commonTypes.HTTP_ERROR}>) =>{
            const displayMessage = () => {
                Modal.error({
                    title:action.payload.error?.message?.toString() || "Error Occured"
                })
            }
            switch (action.payload.key) {
                case Order.getOrderDetails.type:
                    displayMessage();
                    break;
                default:
                    displayMessage();
                    break;
            }
        }
    }
})

export const {
    displayError
} = commonSlice.actions;
export default commonSlice.reducer;