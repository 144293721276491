import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TaxForm from '../../components/tax/taxForm';
import { types } from '@proj/static';
import * as taxServices from '../../services/tax.services';

function Taxes() {
    const [selectedTax, setSelectedTax] = useState<types.miscTypes.TTax | undefined>();
    const [isAddTaxModalVisible, setAddTaxModalVisible] = useState<boolean>(false);
    const [taxes, setTaxes] = useState<types.miscTypes.TTax[]>([]);

    useEffect(() => {
        getTaxes();
    }, []);

    const getTaxes = () => {
        taxServices.getTaxes().then(data=>{
            setTaxes(data);
        })
    };

    const columns: ColumnsType<types.miscTypes.TTax> = [
        {
            title: 'Country Code',
            dataIndex: 'countryCode',
            key: 'countryCode',
        },
        {
            title: 'Province Name',
            dataIndex: 'provinceName',
            key: 'provinceName',
        },
        {
            title: 'Tax Name',
            dataIndex: 'taxName',
            key: 'taxName',
        },
        {
            title: 'Tax Percentage',
            dataIndex: 'taxPercentage',
            key: 'taxPercentage',
            render: (value) => `${value}%`,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button className="primarybtn" onClick={() => onEditTax(record)}>Edit</Button>
                    <Button className="dangerbtn" onClick={()=>{
                        Modal.confirm({
                            title:"Are you sure to delete this tax?",
                            okText:"Yes",
                            onOk:()=>{
                                taxServices.deleteTax(record._id || "").then(()=>{
                                    Modal.success({
                                        title:"Tax deleted successfully!"
                                    })
                                    getTaxes();
                                }).catch(err=>{
                                    Modal.error({
                                        title:"Tax could not be deleted!"
                                    })
                                })
                            }
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];

    const openTaxForm = () => {
        setSelectedTax(undefined);
        setAddTaxModalVisible(true);
    };

    const onEditTax = (tax: types.miscTypes.TTax) => {
        setSelectedTax(tax);
        setAddTaxModalVisible(true);
    };

    return (
        <section className="element-body">
            <div className="heading-block">
                <h2>Taxes</h2>
                <Button type='primary' className="primarybtn" onClick={() => openTaxForm()}>Add Tax</Button>
            </div>

            <div className="table-element">
                <Table columns={columns} dataSource={taxes} pagination={false} />
            </div>

            <Modal
                title={`${selectedTax ? "Update" : "Add"} Tax`}
                open={isAddTaxModalVisible}
                onCancel={() => setAddTaxModalVisible(false)}
                width={650}
                okText="Add"
                className="base-modal"
                footer={[]}
            >
                <TaxForm
                    selectedTax={selectedTax}
                    onSuccess={() => {
                        setAddTaxModalVisible(false);
                        getTaxes();
                        Modal.success({
                            title: `Tax has been ${selectedTax ? "updated" : "created"} successfully`
                        });
                    }}
                />
            </Modal>
        </section>
    );
}

export default Taxes;