import { types } from "@proj/static";
import http from "../utils/http"; 

export const getClients = async() => {
    let result = await (await http().get(`/customer`)).data;
    return result.data;
}

export const createClient = async(data:types.clientTypes.IClient) => {
    let result = await (await http().post(`/customer`,data)).data;
    return result.data;
}

export const updateClient = async(id:string,data:types.clientTypes.IClient
    ) => {
    let result = await (await http().patch(`customer/${id}`,data)).data;
    return result.data;
}

export const deleteClient = async(id:string) => {
    let result = await (await http().delete(`customer/${id}`)).data;
    return result.data;
}

export const getCustomerBasedOnEmail = async(email:string) => {
    let result = await (await http().get(`customer?email=${email}`)).data;
    return result.data;
}

export const getProjects = async(companyId:string) => {
    let result = await (await http().get(`customer/projects?companyId=${companyId}`));
    return result.data;
}