import { types } from "@proj/static";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import {uuid} from 'uuidv4';
import dayjs from 'dayjs';

type TInitialState = {
    isFetchingOrderDetails:boolean
}

const intialState:TInitialState = {
    isFetchingOrderDetails:false,
}

export const OrderSlice = createSlice({
    name:"Order",
    initialState:intialState,
    reducers:{
        getOrderDetails:(state,action:PayloadAction<string>)=>{
            return {
                ...state,
                isFetchingOrderDetails:true
            }
        }
    }
})

export const {
    getOrderDetails
} = OrderSlice.actions;
export default OrderSlice.reducer;