import { takeEvery,put } from "redux-saga/effects";
import * as Auth from '../slices/auth.slice';
import {types} from '@proj/static';
import * as authServices from '../../services/authServices';
import { Modal } from "antd";


function* loginUser(action:types.commonTypes.SAGA_ACTION){
    try{
        yield(authServices.login(action.payload));
    }
    catch(err:any){
        yield put(Auth.onLoginFail(err))
    }
}

function* logoutUser(){
    try{
        yield(authServices.logout());
    }
    catch(err:any){
        Modal.error({title:"Logout failed!"})
    }
}

function* checkLogin(action:types.commonTypes.SAGA_ACTION){
    try{
        let res:types.authTypes.ICheckLoginResponse = yield(authServices.checkEmail(action.payload));
        yield put(Auth.checkEmailSuccess(res))
    }
    catch(err:any){
        yield put(Auth.onLoginFail(err.response.data));
    }
}

function* getUserDetails(){
    try{
        let res:types.authTypes.IGetUserDetailsResponse = yield(authServices.getUserDetails());
        yield put(Auth.getUserDetailsSuccess(res))
    }
    catch(err:any){
        //yield put(Auth.onLoginFail(err.response.data));
    }
}

function* sendPasswordResetEmail(action:types.commonTypes.SAGA_ACTION){
    try{
        yield(authServices.onSendPasswordResetEmail(action.payload));
        yield put(Auth.sendPasswordResetEmailSuccess());
    }
    catch(err){
        Modal.error({title:"Error sending password reset email!"})
    }
}


function* authSaga(){
    yield takeEvery(Auth.loginUser.type,loginUser);
    yield takeEvery(Auth.checkEmail.type, checkLogin);
    yield takeEvery(Auth.logoutUser.type, logoutUser);
    yield takeEvery(Auth.sendPasswordResetEmail.type, sendPasswordResetEmail);
    yield takeEvery(Auth.getUserDetails.type, getUserDetails);
}

export default authSaga;