import { takeEvery,put } from "redux-saga/effects";
import * as Order from '../slices/order.slice';
import {types} from '@proj/static';
import * as orderServices from '../../services/order.services';
import { getError } from "../../utils/error";
import * as Common from '../slices/common.slice';


function* clientSaga(){

}

export default clientSaga;