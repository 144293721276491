import { BrowserRouter } from 'react-router-dom';
import './App.css';
import '../src/assets/style.scss';
import '../src/assets/global.scss';
import '../src/assets/override.scss';
import Router from './Router';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";


function App() {
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState<boolean>(false);

  useEffect(()=>{
    initFirebase()
  },[])

  const initFirebase = async() => {
    const {
      REACT_APP_FIREBASE_API_KEY,
      REACT_APP_FIREBASE_AUTH_DOMAIN,
      REACT_APP_FIREBASE_PROJECT_ID,
      REACT_APP_FIREBASE_STORAGE_BUCKET,
      REACT_APP_FIREBASE_APP_ID
    } = process.env;
    const firebaseConfig = {
      apiKey: REACT_APP_FIREBASE_API_KEY,
      authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
      appId: REACT_APP_FIREBASE_APP_ID
    };
    await initializeApp(firebaseConfig);
    setIsFirebaseInitialized(true)
  }

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Router 
            isFirebaseInitialized={isFirebaseInitialized}
          />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
