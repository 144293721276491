import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { types } from '@proj/static';
import * as TaxServices from '../../services/tax.services';
import { AxiosError } from 'axios';

type FieldType = {
    countryCode?: string;
    provinceName?: string;
    taxName?: string;
    taxPercentage?: number;
};

interface IProps {
    selectedTax?: types.miscTypes.TTax;
    onSuccess?: () => void;
}

function TaxForm(props: IProps) {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (props.selectedTax) {
            form.setFieldsValue(props.selectedTax);
        }
    }, [props.selectedTax, form]);

    const onFinish = (values: any) => {
        setIsSubmitted(true);
        if (props.selectedTax && props.selectedTax._id) {
            TaxServices.updateTax(props.selectedTax._id, values)
                .then(() => {
                    form.resetFields();
                    props.onSuccess && props.onSuccess();
                    setIsSubmitted(false);
                })
                .catch((err: AxiosError) => {
                    message.error("Error Occurred!");
                    setIsSubmitted(false);
                });
        } else {
            TaxServices.createTax(values)
                .then(() => {
                    form.resetFields();
                    props.onSuccess && props.onSuccess();
                    setIsSubmitted(false);
                })
                .catch((err: AxiosError) => {
                    message.error("Error Occurred!");
                    setIsSubmitted(false);
                });
        }
    };

    return (
        <Form
            name="taxForm"
            labelCol={{ span: 6 }}
            labelAlign='left'
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<FieldType>
                className="form-box"
                label="Country Code"
                name="countryCode"
                rules={[{ required: true, message: 'Country Code is required!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Province Name"
                name="provinceName"
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Tax Name"
                name="taxName"
                rules={[{ required: true, message: 'Tax Name is required!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                className="form-box"
                label="Tax Percentage"
                name="taxPercentage"
                rules={[
                    { required: true, message: 'Tax Percentage is required!' },
                    { type: 'number', min: 0, max: 100, message: 'Must be between 0 and 100' }
                ]}
            >
                <InputNumber min={0} max={100} formatter={value => `${value}%`} />
            </Form.Item>

            <Form.Item className="modal-btn">
                {props.selectedTax ? (
                    <Button
                        htmlType="submit"
                        className="primarybtn"
                        disabled={isSubmitted}
                    >
                        {isSubmitted ? 'Updating...' : 'Update'}
                    </Button>
                ) : (
                    <Button
                        htmlType="submit"
                        className="primarybtn"
                        disabled={isSubmitted}
                    >
                        {isSubmitted ? 'Adding...' : 'Add'}
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
}

export default TaxForm;